<script setup>
const props = defineProps({
  theme: {
    type: String,
    required: false,
    default: 'grey',
    validator: (value) => ['grey', 'pink'].includes(value),
  },
  label: {
    type: String,
    required: false,
    default: null,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  error: {
    type: Number,
    required: false,
    default: 0,
  },
  success: {
    type: Boolean,
  },
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  autocomplete: {
    type: [Boolean, String],
    default: true,
  },
  rows: {
    type: Number,
    required: false,
    default: 7,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  required: Boolean,
  errorMessages: {
    type: Array,
    required: false,
    default: () => [],
  },
  autoFocus: Boolean,
  focusOnMount: {
    type: Boolean,
    default: false,
  },
  minlength: {
    type: Number,
    default: null,
  },
  maxlength: {
    type: Number,
    default: null,
  },
  pattern: {
    type: String,
    default: null,
  },
  disableArrows: {
    type: Boolean,
    default: false,
  },
})

const slots = useSlots()

const value = defineModel({ type: [String, Number] })

const input = ref(null)

const errorMessage = computed(() => {
  let result = ''
  if (
    props.error > 0 &&
    props.errorMessages &&
    props.errorMessages.length &&
    props.errorMessages.length >= props.error
  ) {
    result = props.errorMessages[props.error - 1]
  }

  return result
})

const hasPrefix = computed(() => {
  return slots.prefix
})

const hasPrefixWithBorder = computed(() => {
  return slots['prefix-with-border']
})

const hasIconRight = computed(() => {
  return slots['icon-right']
})

onMounted(() => {
  if (props.focusOnMount) {
    input.value?.focus()
  }
})
</script>

<template>
  <div>
    <label
      v-if="label"
      :for="label"
      class="text-primary-400 block font-sans text-sm"
    >
      {{ label }}
    </label>
    <div
      class="relative flex overflow-hidden rounded-md"
      :class="{
        'mt-1': label,
      }"
    >
      <!-- <div v-if="hasPrefix" class="bg-gray-50 flex items-center pl-3">
        <slot name="prefix"></slot>
      </div> -->
      <textarea
        v-if="type === 'textarea'"
        :id="label"
        ref="input"
        v-model="value"
        :name="label"
        :autofocus="autoFocus ? '' : null"
        :disabled
        :required
        :placeholder
        :rows
        class="block !min-h-0 w-full resize-none appearance-none bg-gray-50 px-4 py-4 text-base text-gray-800 placeholder-gray-600 focus:placeholder-gray-900 focus:outline-none"
        :class="{
          'placeholder-primary-500': error <= 0,
          'placeholder-red': error > 0,
        }"
      ></textarea>
      <input
        v-else
        :id="label"
        ref="input"
        v-model="value"
        :name="label"
        :autocomplete="!autocomplete ? 'new-password' : autocomplete"
        :autofocus="autoFocus ? '' : null"
        :required
        :disabled
        :type
        :placeholder
        :minlength
        :maxlength
        :pattern
        class="font-averta-pe block w-full border-b-2 bg-gray-50 px-4 py-3 text-base placeholder-gray-500 focus:placeholder-gray-900 focus:outline-none"
        :class="{
          'border-transparent focus:border-gray-600':
            error <= 0 && theme === 'grey',
          'focus:border-pink border-transparent':
            error <= 0 && theme === 'pink',
          ' border-red': error > 0,
          '!pl-10': hasPrefix,
          '!pl-16': hasPrefixWithBorder,
          'cursor-not-allowed !text-slate-500': disabled,
          'h-10 min-w-full items-center flex': type === 'datetime-local',
          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none':
            disableArrows,
        }"
      />
      <div
        v-if="hasPrefix"
        class="pointer-events-none absolute inset-y-0 left-0 z-20 flex items-center space-x-1"
      >
        <div class="pl-4">
          <slot name="prefix" />
        </div>
      </div>

      <div
        v-if="hasPrefixWithBorder"
        class="pointer-events-none absolute inset-y-0 left-0 z-20 flex items-center space-x-1"
      >
        <div class="flex h-6 items-center border-r border-gray-300 pl-4">
          <slot name="prefix-with-border" />
        </div>
      </div>

      <div
        class="pointer-events-none absolute inset-y-0 right-0 z-20 flex items-center space-x-1"
      >
        <div v-if="error > 0" :class="{ 'pr-4': !hasIconRight }">
          <IconCross class="text-red h-3 w-3" />
        </div>
        <div v-if="hasIconRight">
          <slot name="icon-right" />
        </div>
      </div>
    </div>
    <p
      v-if="error > 0"
      id="error"
      class="font-averta-pe text-red mt-2 px-4 text-base"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
